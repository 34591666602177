import { ApiClient } from '../ApiClient';

export class NftsApi {
  /**
   * Constructs a new NftsApi.
   * @alias module:api/NftsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * List all ERC721 &amp; ERC1155 that have been minted on chain
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   * @param {Number} buyNow Whether to fetch all nfts (0) or only which have been listed (1)
   */
  async list({ skip, limit, buyNow, owner }) {
    return await this.apiClient.callApi('/nfts', 'get', {}, { skip, limit, buyNow, owner });
  }

  /**
   * List all ERC721 &amp; ERC1155 from a contract address
   *
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   * @param {string} address Number of items to retrieve
   * @param {Number} buyNow Whether to fetch all nfts (0) or only which have been listed (1)
   */
  async getNftsFromAddress({ skip, limit, address, buyNow, tokenIds, traits, min, max }) {
    return await this.apiClient.callApi(
      '/nfts/{address}',
      'get',
      { address },
      { skip, limit, buyNow, tokenIds, ...traits, min, max },
    );
  }

  /**
   * Get NFT from tokenId and contract address
   *
   * @param {string} address Contract address of the token
   * @param {string} tokenId tokenId to retrieve data
   */
  async getNftTokenFromId({ contractAddress, tokenId }) {
    return await this.apiClient.callApi(
      '/nfts/{contractAddress}/{tokenId}',
      'get',
      { contractAddress, tokenId },
      {},
    );
  }

  /**
   * Get NFTs owned by a user
   * @param {string} address Contract address of the token
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getUserOwnedNfts(address, skip, limit) {
    const body = { skip, limit, owner: address, buyNow: 0 };
    return await this.apiClient.callApi('/nfts', 'get', {}, body);
  }

  /**
   * Get trading history of NFT
   * @param {string} contractAddress Contract address of the token
   * @param {string} tokenId tokenId to retrieve data
   * @param {string} listing Listing tx type - 'true' | 'false'
   * @param {string} mint Mint tx type - 'true' | 'false'
   * @param {Number} sale Sale tx type - 'true' | 'false'
   * @param {Number} offer Offer tx type - 'true' | 'false'
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getTradingHistory({
    contractAddress,
    tokenId,
    listing,
    mint,
    sale,
    offer,
    bid,
    burn,
    transfer,
    skip,
    limit,
  }) {
    return await this.apiClient.callApi(
      '/nfts/tradingHistory/{contractAddress}/{tokenId}',
      'get',
      { contractAddress, tokenId },
      { listing, mint, sale, offer, bid, burn, transfer, skip, limit },
      null,
    );
  }

  /**
   * Get all offer for specific NFT
   * @param {string} contractAddress Contract address of the token
   * @param {string} tokenId tokenId to retrieve data
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   * @param {Number} direction Created at direction (asc, desc)
   */
  async getAssetOffers({ contractAddress, tokenId, skip, limit, direction }) {
    return await this.apiClient.callApi(
      '/nfts/offers/{contractAddress}/{tokenId}',
      'get',
      { contractAddress, tokenId },
      { skip, limit, direction },
      null,
    );
  }

  /**
   * Get all bids for specific NFT
   * @param {Array.<string>} contractAddresses Contract addresses of the token
   * @param {Number} count Number of items to retrieve
   */
  async getNftBids({ contractAddresses, ownerAddress, limit, exclude }) {
    return await this.apiClient.callApi(
      '/nfts/bids/{ownerAddress}',
      'get',
      { ownerAddress },
      { contractAddresses, limit, exclude },
      null,
    );
  }

  /**
   * Get all offer for specific NFT
   * @param {string} contractAddress Contract address of the token
   * @param {string} tokenId tokenId to retrieve data
   * @param {string} period Period which calculate the statistic. '1H' | '3H' | '12H' | '1D' | '3D' | '1W' | '2W' | '1M' | '2M' | '3M' | '6M' | '1Y' | 'all'
   * @param {string} direction Price direction (asc, desc)
   * @param {Boolean} includeDaysWithoutTrade If true, will return all days with 0 trade
   * @param {Boolean} includeAvgPrice If true, will return all-time average price of the NFT
   */
  async getAssetPriceHistory({
    contractAddress,
    tokenId,
    period,
    direction,
    includeDaysWithoutTrade,
    includeAvgPrice,
  }) {
    return await this.apiClient.callApi(
      '/nfts/priceHistory/{contractAddress}/{tokenId}',
      'get',
      { contractAddress, tokenId },
      { period, direction, includeDaysWithoutTrade, includeAvgPrice },
      null,
    );
  }

  /**
   * Get trading history for given Collection
   * @param {string} contractAddress Contract address of the collection
   * @param {string} listing Listing tx type - 'true' | 'false'å
   * @param {string} mint Mint tx type - 'true' | 'false'
   * @param {Number} sale Sale tx type - 'true' | 'false'
   * @param {Number} offer Offer tx type - 'true' | 'false'
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getCollectionTradingHistory({
    contractAddress,
    listing,
    mint,
    sale,
    offer,
    bid,
    burn,
    transfer,
    skip,
    limit,
  }) {
    return await this.apiClient.callApi(
      '/collections/tradingHistory/{contractAddress}',
      'get',
      { contractAddress },
      { listing, mint, sale, offer, bid, burn, transfer, skip, limit },
      null,
    );
  }

  /**
   * Get price history for given Collection
   * @param {string} contractAddress Contract address of the collection
   * @param {string} period Period which calculate the statistic. '1H' | '3H' | '12H' | '1D' | '3D' | '1W' | '2W' | '1M' | '2M' | '3M' | '6M' | '1Y' | 'all'
   * @param {string} direction Price direction (asc, desc)
   * @param {Boolean} includeDaysWithoutTrade If true, will return all days with 0 trade
   * @param {Boolean} includeAvgPrice If true, will return all-time average price of the NFT
   */
  async getCollectionPriceHistory({
    contractAddress,
    period,
    direction,
    includeDaysWithoutTrade,
    includeAvgPrice,
  }) {
    return await this.apiClient.callApi(
      '/collections/priceHistory/{contractAddress}',
      'get',
      { contractAddress },
      { period, direction, includeDaysWithoutTrade, includeAvgPrice },
      null,
    );
  }

  /**
   * Refresh metadata for specific NFT
   * @param {string} contractAddress Contract address of the nft
   * @param {string} tokenId tokenId of the nft
   */
  async refreshMetadata({ contractAddress, tokenId }) {
    return await this.apiClient.callApi(
      '/nfts/{contractAddress}/{tokenId}/metadata',
      'get',
      { contractAddress, tokenId },
      {},
      null,
    );
  }

  async getNftsByOwnerInCollection({ owner, contractAddress, skip, limit }) {
    return await this.apiClient.callApi(
      '/nfts/from/{owner}/{contractAddress}',
      'get',
      { owner, contractAddress },
      { skip, limit },
    );
  }
}
