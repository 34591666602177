import { useContext, useMemo } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import {
  Add as AddIcon,
  Percent as PercentIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { TOTAL_ROYALTY_PERCENTAGE } from 'constants';
import { CheckoutModalContext } from 'pages/context';
import { areEqualObjects, isAddress } from 'utils';
import useRoyalties from 'hooks/useRoyalties';
import useNotification from 'hooks/useNotification';
import { getFullMediaUrl } from 'utils/image';

import { PrimaryLink, VerticalFlexBox } from 'components/StyledComponents';

const AddAddressButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeigh: 600,
  color: theme.palette.button.tertiary,
}));

const Input = styled(TextField)(({ theme, width, height, minwidth }) => ({
  background: theme.palette.background.secondary,
  letterSpacing: '0.25px',
  width: width ?? 108,
  minWidth: minwidth ?? 'auto',
  borderRadius: 8,
  marginRight: theme.spacing(2),

  '& fieldset': {
    border: `1px solid ${theme.palette.border.decorative}`,
    borderRadius: 8,
    padding: 0,
  },
  '& input::placeholder': {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.secondary,
    opacity: 1,
    zIndex: 1000,
  },
  '& input': {
    color: theme.palette.text.main,
    padding: theme.spacing(0, 2),
    height: height ?? 40,
    fontSize: 14,
  },

  '&:hover': {
    background: theme.palette.background.apply.hovered,
    '& .MuiTextField-root': {
      borderColor: theme.palette.border.decorative,
      borderRadius: 8,
    },
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    '& .MuiTextField-root': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '@media (max-width: 600px)': {
    '& .MuiInputBase-root': {
      paddingRight: theme.spacing(0.5),
    },
    '& input': {
      padding: 0,
      paddingLeft: theme.spacing(1),
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
}));

const DeleteIcon = styled(DeleteOutlinedIcon)(() => ({
  width: 24,
  height: 24,
}));

const SaveButton = styled(Button)(({ theme, loading }) => ({
  width: loading === 'true' ? 'auto' : 146,
  minWidth: 146,
  height: 40,
  padding: theme.spacing(1, 2),
  borderRadius: 8,
  fontSize: 16,
  fontWeight: 600,
  backgroundColor: theme.palette.button.primary,

  '&:disabled': {
    background: theme.palette.button.primary,
    opacity: 0.24,
    color: theme.palette.white,
  },
  '@media (max-width: 600px)': {
    marginTop: theme.spacing(2),
    float: 'right',
  },
}));

const ErrorList = styled('ul')(({ theme }) => ({
  width: 505,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.text.error,
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  float: 'left',

  '@media (max-width: 600px)': {
    width: '100%',
  },
}));

const InputSkeleton = styled(Skeleton)(({ theme, minwidth }) => ({
  marginRight: theme.spacing(2),
  borderRadius: 8,
  minWidth: minwidth ?? 'auto',
}));

const Thumbnail = styled('img')(({ theme }) => ({
  width: 180,
  height: 180,
  borderRadius: 8,
  border: `2px solid ${theme.palette.border.main}`,
}));

const Earnings = ({ collection }) => {
  const { name, address, contractThumbnailPathName } = collection;
  const [earnings, setEarnings] = useStateIfMounted([]);
  const { openModal } = useContext(CheckoutModalContext);
  const { currentEarnings, setCurrentEarnings, save, isLoading, isSaving } = useRoyalties({
    address,
  });
  const notification = useNotification();
  const thumbnail = getFullMediaUrl(contractThumbnailPathName);

  const below600 = useMediaQuery('(max-width: 600px)');
  const below700 = useMediaQuery('(max-width: 700px)');

  const total =
    earnings.map((item) => item.percentage).reduce((a, b) => Number(a) + Number(b) * 100, 0) / 100;
  const totalError = total < TOTAL_ROYALTY_PERCENTAGE.MIN || total > TOTAL_ROYALTY_PERCENTAGE.MAX;

  const earningsAddresses = earnings.map((item) => item.earnerAddress.toLowerCase());

  const actualAddresses = earningsAddresses.filter((item) => isAddress(item));
  const duplicateAddress = actualAddresses.length !== new Set(actualAddresses).size;

  const wrongAddresses = earningsAddresses.filter((item) => item != '' && !isAddress(item));
  const wrongAddressesIndex = wrongAddresses.map((item) => earningsAddresses.indexOf(item));
  const wrongAddressesNumbers = wrongAddressesIndex.map((item) => `#${item + 1}`).join(' , ');

  const emptyFields = earnings.filter(
    (item) => item.earnerAddress === '' || item.percentage === '',
  );

  const zeroPercentageFields = earnings.filter((item) => item.percentage == 0);

  const disabled =
    duplicateAddress ||
    totalError ||
    isSaving ||
    Boolean(wrongAddressesIndex.length) ||
    Boolean(emptyFields.length) ||
    Boolean(zeroPercentageFields.length) ||
    areEqualObjects(earnings, currentEarnings, {
      caseSensitive: true,
    });

  const confirmationModalContent = (
    <VerticalFlexBox>
      <Thumbnail src={thumbnail} />
      <Typography marginTop={2} fontWeight={500}>
        Setting Royalties for {` `}
        <PrimaryLink target="_blank" rel="noopener noreferer" href={`/collections/${address}`}>
          {name}
        </PrimaryLink>
      </Typography>
    </VerticalFlexBox>
  );

  const loadingSkeleton =
    isLoading &&
    [...Array(3).keys()].map((item) => (
      <Box key={item} marginBottom={3} display="flex" alignItems="center">
        <InputSkeleton variant="rectangular" width={503} minwidth={192} height={48} />
        <InputSkeleton variant="rectangular" width={132} minwidth={64} height={48} />
        <Skeleton
          variant="circular"
          sx={{ borderRadius: '50%', minWidth: 40 }}
          width={40}
          height={40}
        />
      </Box>
    ));

  const handleSave = async () => {
    try {
      const hash = await save(earnings);
      openModal(
        {
          hash,
          callbackOnSuccess: () => setCurrentEarnings(earnings),
          content: confirmationModalContent,
        },
        'tx_confirmation',
        null,
      );
    } catch (error) {
      notification({
        message: error.shortMessage,
        type: 'error',
      });
    }
  };

  const handleChange = (index, key) => (e) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    let value = e.target.value;
    if (key === 'percentage') {
      if (!regex.test(e.target.value)) {
        return;
      }
      value = Math.min(Number(e.target.value), TOTAL_ROYALTY_PERCENTAGE.MAX);
    }
    const newEarnings = earnings.map((item, itemIndex) => {
      if (index === itemIndex) {
        return {
          ...item,
          [key]: value,
        };
      }
      return { ...item };
    });
    setEarnings(newEarnings);
  };

  const deleteEarning = (index) => {
    let newEarnings = [...earnings];
    newEarnings.splice(index, 1);
    setEarnings(newEarnings);
  };

  useMemo(() => {
    setEarnings(currentEarnings);
  }, [currentEarnings]);

  return (
    <>
      <Typography fontSize={below600 ? 14 : 32} fontWeight={500} margin="16px 0 8px">
        Royalty Management
      </Typography>
      {loadingSkeleton}
      {earnings.map((item, index) => (
        <Box key={index} marginBottom={3} display="flex" alignItems="center">
          <Input
            placeholder="Enter Address"
            width={503}
            minwidth={192}
            height={48}
            value={item.earnerAddress}
            onChange={handleChange(index, 'earnerAddress')}
          />
          <Input
            placeholder="0"
            width={132}
            minwidth={64}
            height={48}
            value={item.percentage}
            onChange={handleChange(index, 'percentage')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon sx={{ fontSize: 16 }} />
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={() => deleteEarning(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Box
        display="flex"
        width={below700 ? '100%' : 650}
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <AddAddressButton
          onClick={() => setEarnings([...earnings, { earnerAddress: '', percentage: '' }])}
          startIcon={<AddIcon />}
        >
          Add Address
        </AddAddressButton>
        {Boolean(earnings.length) && (
          <Typography fontSize={16} fontWeight={600}>
            Total: {total}%
          </Typography>
        )}
      </Box>
      {Boolean(earnings.length) && (
        <Box
          display="flex"
          alignItems={below600 ? 'flex-end' : 'center'}
          flexDirection={below600 ? 'column' : 'row'}
        >
          <ErrorList>
            {wrongAddressesNumbers && <li>{`Wrong address in ${wrongAddressesNumbers}`}</li>}
            {duplicateAddress && <li>{"You can't define the same address more than once"}</li>}
            {totalError && (
              <li>{`Total creator earnings must be between ${TOTAL_ROYALTY_PERCENTAGE.MIN}% and ${TOTAL_ROYALTY_PERCENTAGE.MAX}%`}</li>
            )}
            {Boolean(zeroPercentageFields.length) && (
              <li>{'Minimum earning percentage should be more than 0%'}</li>
            )}
          </ErrorList>
          <SaveButton
            disabled={disabled}
            loading={isSaving.toString()}
            variant="contained"
            onClick={handleSave}
          >
            {isSaving ? <span className="loading">Saving Earnings...</span> : 'Save Earnings'}
          </SaveButton>
        </Box>
      )}
    </>
  );
};

export default Earnings;
