import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import marketplace from 'services/marketplace';

import { MAX_FILE_SIZE } from 'constants';
import useNotification from 'hooks/useNotification';
import { getFullMediaUrl } from 'utils/image';

import { StandardButton } from 'components/Button';
import { GreenText } from 'components/Text';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Label = styled('label')(({ theme }) => ({
  position: 'relative',
  fontSize: 16,
  fontWeight: 600,
  height: 200,
  width: 200,
  marginTop: theme.spacing(2),
  '& svg': {
    color: theme.palette.background.secondary,
  },
  paddingLeft: theme.spacing(3),
}));

const StyledImage = styled('img')(() => ({
  width: 200,
  height: 200,
  cursor: 'pointer',
  zIndex: 0,
  objectFit: 'cover',

  '& .Mui-hovered': {
    filter: 'brightness(.5)',
    border: '3px solid red',
  },
}));

const HoverContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 200,
  height: 200,
  cursor: 'pointer',
  zIndex: 1,
  backgroundColor: '#000',
  opacity: 0.8,
}));

const HiddenFileInput = styled('input')(() => ({
  visibility: 'hidden',
  height: 0,
  width: 0,
}));

const ChangeLogo = ({ collection, setCollection }) => {
  const below600 = useMediaQuery('(max-width:600px)');
  const showNotification = useNotification();

  const [isHover, setHover] = useStateIfMounted(false);
  const [file, setFile] = useStateIfMounted('');
  const [loading, setLoading] = useStateIfMounted(false);

  const address = collection?.address;
  const image = collection?.contractThumbnailPathName;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file.size > MAX_FILE_SIZE.value) {
      return showNotification({
        type: 'error',
        message: `File size should not exceed ${MAX_FILE_SIZE.label}`,
      });
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      setFile(event.target.result);
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!file) {
        return showNotification({ type: 'error', message: 'Please select an image' });
      }

      const data = {
        collectionAddress: address,
        thumbnail: file,
      };
      const response = await marketplace.updateCollectionImage({ data });
      if (!response.success) {
        showNotification({ type: 'error', message: response.message });
        return;
      }

      setCollection((prev) => ({
        ...prev,
        contractThumbnailPathName: file,
      }));
      showNotification({ type: 'success', message: 'Collection logo updated successfully' });
    } catch (error) {
      console.error(error);
      showNotification({ type: 'error', message: 'Failed to update collection logo' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Typography fontSize={below600 ? 14 : 32} fontWeight={500}>
          Change Logo
        </Typography>
        <Label onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          {(file || image) && <StyledImage src={file || getFullMediaUrl(image)} />}
          {isHover && (
            <HoverContainer>
              <EditIcon />
            </HoverContainer>
          )}
          <HiddenFileInput
            name="collectionThumbnail"
            id="field-collectionThumbnail"
            type="file"
            accept=".jpeg,.jpg,.png,.gif,.svg,.webp"
            onChange={handleFileChange}
          />
        </Label>
      </Container>
      <Box display="flex" alignItems="center" marginTop={2}>
        <Box width={300}>
          <GreenText fs={14} fw={500} align="left">
            Recommended Size: 350x350px
          </GreenText>
          <GreenText fs={14} fw={500} align="left">
            Max Size: 10 MB
          </GreenText>
        </Box>
        <StandardButton onClick={handleSave} disabled={!file.length || loading}>
          {!loading ? 'Save Logo' : <span className="loading">Saving Logo...</span>}
        </StandardButton>
      </Box>
    </>
  );
};

export default ChangeLogo;
