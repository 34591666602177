import { CollectionsApi, UserApi } from 'clientSDK';
import dotenv from 'dotenv';
dotenv.config();

const collectionsApi = new CollectionsApi();
const userApi = new UserApi();

export default {
  getCollectionsByOwner: async (address) => await collectionsApi.getCollectionByOwner({ address }),

  updateSocials: async ({ address, body }) => await userApi.updateSocials({ address, body }),
};
