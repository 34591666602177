import DefaultIcon from 'assets/images/GMI-icon.svg';

export const getFullMediaUrl = (url, backupUrl) => {
  if (!url) {
    return backupUrl?.startsWith('https') ? backupUrl : DefaultIcon;
  }
  return url.startsWith('https') || url.startsWith('data:image')
    ? url
    : `${process.env.REACT_APP_MEDIA_BASE_URL}${url}`;
};
