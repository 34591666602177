import React from 'react';
import { Typography, Box, styled, useTheme } from '@mui/material';
import {
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { NETWORK, CHAIN_ID } from 'constants/index';

import { GeneralFlexBox, ToolTip } from 'components/StyledComponents';
import {
  CheckoutModalRow,
  ModalHeaderContainer,
  ModalIconButton,
} from '../components/styledComponents';
import { StandardButton } from 'components/Button';
import { ReactComponent as BalanceWalletLight } from 'assets/images/balance-wallet-light.svg';
import { ReactComponent as BalanceWalletDark } from 'assets/images/balance-wallet.svg';

const symbol = NETWORK[CHAIN_ID].symbol;

const MainContainer = styled(GeneralFlexBox)(() => ({
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(({ path }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  padding: '0px 24px 20px 24px',

  '@media (max-width: 500px)': {
    justifyContent: path !== 'buy' ? 'center' : 'flex-start',
    padding: '0px 24px 16px 24px',
  },
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
  '@media (max-width: 500px)': {
    alignItems: 'flex-start',
  },
}));

const WalletAddressBox = styled(GeneralFlexBox)(({ theme }) => ({
  height: '48px',
  width: '353px',
  padding: '14px',
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: '8px',

  '@media(max-width: 500px)': {
    width: '223px',
  },
}));
/********************  Main Component  ********************/
const AddFunds = ({
  closeModal,
  address,
  path,
  prevModal = null,
  closeSecondaryModal = () => {},
}) => {
  const theme = useTheme();

  const [showTooltip, setShowTooltip] = useStateIfMounted(false);

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(address);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 3000);
  };

  return (
    <MainContainer direction="column" width="100%">
      <ModalHeaderContainer>
        {prevModal ? (
          <ModalIconButton onClick={closeSecondaryModal}>
            <ArrowBackIcon style={{ fill: theme.palette.icon.tertiary }} />
          </ModalIconButton>
        ) : (
          <Box width={30}></Box> // a dummy box
        )}
        <Typography fontSize={24} fontWeight={500}>
          Add Funds
        </Typography>
        <ModalIconButton onClick={closeModal}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      </ModalHeaderContainer>

      <MiddleRow path={path}>
        {theme.palette.mode === 'light' ? (
          <BalanceWalletLight
            width={138}
            height={138}
            style={{ marginTop: -20, marginBottom: -20 }}
          />
        ) : (
          <BalanceWalletDark
            width={138}
            height={138}
            style={{ marginTop: -20, marginBottom: -20 }}
          />
        )}
        <Typography fontSize={14} textAlign="center" marginBottom="20px">
          Use wallet address below to transfer funds from an exchange or to another wallet.{' '}
        </Typography>
        <Box style={{ display: 'flex', gap: '10px' }}>
          <WalletAddressBox>
            <Typography
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              fontSize="14px"
              fontWeight={500}
              color={theme.palette.text.secondary}
            >
              {address}
            </Typography>
          </WalletAddressBox>
          <ToolTip open={showTooltip} title="Copied to Clipboard" placement="top">
            <StandardButton
              variant="contained"
              width={73}
              height={48}
              onClick={copyAddressToClipboard}
              disableRipple
            >
              Copy
            </StandardButton>
          </ToolTip>
        </Box>
      </MiddleRow>
      <CheckoutFooterRow>
        <ErrorOutlineIcon style={{ fill: theme.palette.support.warning2 }} />
        <Typography
          fontSize="12px"
          fontWeight={400}
          marginLeft={2}
          color={theme.palette.text.secondary}
          textAlign="center"
        >
          Only send {`${symbol}`} or any other ERC-20 token to this address.
        </Typography>
      </CheckoutFooterRow>
    </MainContainer>
  );
};

export default React.memo(AddFunds);
