export const formatNumber = (number) => {
  if (isNaN(number)) {
    return '';
  }
  return number?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatBigDecimalNumbers = (number, max) => {
  if (isNaN(number)) {
    return '';
  }
  return number?.toLocaleString('en-US', {
    minimumFractionDigits: max === 0 ? 0 : Math.min(max ?? 2, 2),
    maximumFractionDigits: max ?? 6,
  });
};

export const setBalanceWithDecimals = (rawBalance, decimals) => {
  return (Number(rawBalance) / Math.pow(10, decimals)).toFixed(6);
};

export const compactNumber = (number, formatThousand = false, decimals = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: decimals,
  });
  // Detailed formatter for numbers less than 1000
  const detailedFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
  });

  if (!number) {
    return 0;
  }
  if (number >= 1000000 || (formatThousand && number >= 1000)) {
    return formatter.format(number);
  }
  return detailedFormatter.format(number);
};

export const removeCommas = (numberString) => {
  if (typeof numberString !== 'string') {
    return numberString;
  }
  return numberString?.replace(/,/g, '');
};
