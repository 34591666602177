import { useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, Button, Skeleton, Typography, styled, useMediaQuery, useTheme } from '@mui/material';

import { NETWORK } from 'constants';
import { useUserCollectionsContext } from 'context/UserCollectionsContext';
import useWalletContext from 'hooks/useWalletContext';
import { getFullMediaUrl } from 'utils/image';

import { CustomLink, VerticalFlexBox } from 'components/StyledComponents';
import { NoBidsSVG } from 'components/ImageComponent';
import { EditCollection } from './components';

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 15),
  background: theme.palette.background.default,

  '@media (max-width: 900px)': {
    padding: theme.spacing(0, 3),
  },
  '@media (max-width: 600px)': {
    padding: theme.spacing(2, 2),
  },
}));

const CollectionItem = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: 8,
  padding: theme.spacing(2.5, 3),
  margin: theme.spacing(2, 0),
  display: 'flex',

  '@media (max-width: 560px)': {
    flexDirection: 'column',
  },
}));

const CollectionAvatar = styled('img')(({ theme }) => ({
  width: 84,
  height: 84,
  borderRadius: 84,
  border: `1px solid ${theme.palette.border.decorative}`,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 600,

  '@media (max-width: 560px)': {
    marginTop: theme.spacing(2),
  },
}));

const CollectionCardSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: 8,
  marginBottom: theme.spacing(3),
  height: 125,
}));

const CreatorButton = styled(CustomButton)(({ theme }) => ({
  background: '#1A3428',
  border: `1px solid ${theme.palette.border.highlight}`,
  color: theme.palette.text.highlight,
}));

const MyCollections = ({ collectionAddress }) => {
  const theme = useTheme();
  const below600 = useMediaQuery('(max-width: 600px)');

  const history = useHistory();
  const { chainId } = useWalletContext();
  const { collections, loading } = useUserCollectionsContext();

  const [activeCollection, setActiveCollection] = useStateIfMounted(null);

  const basePath = '/account/settings/collections/';
  const creatorBasePath = '/creator/';
  const collection = collections.find((item) => item.address === collectionAddress);
  const symbol = NETWORK[chainId]?.symbol;

  const collectionsSkeleton = (
    <>
      <CollectionCardSkeleton variant="rectangular" />
      <CollectionCardSkeleton variant="rectangular" />
      <CollectionCardSkeleton variant="rectangular" />
    </>
  );

  const collectionsList = (
    <>
      {!below600 && (
        <Typography fontSize={below600 ? 24 : 42} fontWeight={500} marginBottom={3}>
          My Collections
        </Typography>
      )}
      {loading
        ? collectionsSkeleton
        : collections.map((item) => (
            <CollectionItem key={item.id}>
              <Box display="flex" flex={1} justifyContent={'space-around'}>
                <Box>
                  <CustomLink to={`/collections/${item.address}`}>
                    <CollectionAvatar
                      src={getFullMediaUrl(
                        activeCollection?.address === item.address
                          ? activeCollection.contractThumbnailPathName
                          : item.contractThumbnailPathName,
                      )}
                    />
                  </CustomLink>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  paddingLeft={3}
                  flexDirection="column"
                >
                  <CustomLink to={`/collections/${item.address}`}>
                    <Typography fontSize={12} fontWeight={500} color="text.secondary">
                      {item.circulatingSupply ? item.circulatingSupply.toLocaleString() : 0}{' '}
                      {item.circulatingSupply <= 1 ? 'item' : 'items'}
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {item.name}
                    </Typography>
                    <Typography fontSize={12} fontWeight={500}>
                      Floor price: {item.floorPrice || '-'} {symbol}
                    </Typography>
                  </CustomLink>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                {item.mintDetails?.collectionUrl && (
                  <CreatorButton
                    variant="contained"
                    onClick={() => history.push(creatorBasePath + item.mintDetails?.collectionUrl)}
                  >
                    Creator Console
                  </CreatorButton>
                )}
                <CustomButton
                  variant="contained"
                  onClick={() => history.push(basePath + item.address)}
                >
                  Edit Collection
                </CustomButton>
              </Box>
            </CollectionItem>
          ))}
    </>
  );

  const noCollections = (
    <VerticalFlexBox sx={{ marginTop: '15%' }}>
      <NoBidsSVG />
      <p
        style={{
          color: theme.palette.text.main,
        }}
      >
        No Collections
      </p>
    </VerticalFlexBox>
  );

  return (
    <Container>
      {collection ? (
        <EditCollection collection={collection} setCollection={setActiveCollection} />
      ) : !loading && collections.length === 0 ? (
        noCollections
      ) : (
        collectionsList
      )}
    </Container>
  );
};

export default MyCollections;
