import { Typography, useMediaQuery } from '@mui/material';

import { ChangeLogo, Earnings, SocialMedia } from './components';

const EditCollection = ({ collection, setCollection }) => {
  const below600 = useMediaQuery('(max-width:600px)');

  const name = collection.name;

  return (
    <>
      <Typography fontSize={below600 ? 16 : 42} fontWeight={500} marginBottom={1}>
        Edit Collection - {name}
      </Typography>
      <ChangeLogo collection={collection} setCollection={setCollection} />
      <Earnings collection={collection} />
      <SocialMedia collection={collection} />
    </>
  );
};

export default EditCollection;
