import { Box, InputAdornment, styled, TextField, Typography, useMediaQuery } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';

import collectionService from 'services/collection';

import { SOCIAL_LINKS } from 'constants';
import useNotification from 'hooks/useNotification';

import { GeneralFlexBox } from 'components/StyledComponents';
import { StandardButton } from 'components/Button';

const CustomTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.secondary,
  letterSpacing: '0.25px',
  borderRadius: 8,
  marginRight: theme.spacing(2),
  width: '100%',
  maxWidth: 650,

  '& fieldset': {
    border: `1px solid ${theme.palette.border.decorative}`,
    borderRadius: 8,
    padding: 0,
  },
  '& input::placeholder': {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.secondary,
    opacity: 1,
    zIndex: 1000,
  },
  '& input': {
    color: theme.palette.text.main,
    padding: 0,
    height: 48,
    fontSize: 14,
  },

  '&:hover': {
    background: theme.palette.background.apply.hovered,
    '& .MuiTextField-root': {
      borderColor: theme.palette.border.decorative,
      borderRadius: 8,
    },
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    '& .MuiTextField-root': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '@media (max-width: 600px)': {
    '& .MuiInputBase-root': {
      paddingRight: theme.spacing(0.5),
    },
    '& input': {
      padding: 0,
      paddingLeft: theme.spacing(1),
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
}));

const SocialMedia = ({ collection }) => {
  const below600 = useMediaQuery('(max-width:600px)');
  const showNotification = useNotification();

  const keys = ['twitterUsername', 'instagramUsername', 'discordUrl', 'externalUrl'];
  const [socialLinks, setSocialLinks] = useStateIfMounted(
    keys.map((key) => collection?.[key] || ''),
  );
  const [loading, setLoading] = useStateIfMounted(false);

  const disabled = keys.every((key, index) => (collection?.[key] || '') === socialLinks[index]);

  const handleSave = async () => {
    try {
      setLoading(true);

      const data = keys.reduce((acc, key, index) => {
        acc[key] = socialLinks[index] || null;
        return acc;
      }, {});
      const response = await collectionService.updateSocials({
        address: collection.address,
        body: data,
      });
      if (!response.success) {
        throw new Error(response);
      }

      showNotification({ type: 'success', message: 'Social links updated successfully' });
    } catch (error) {
      console.error(error);
      showNotification({ type: 'error', message: 'Failed to update collection logo' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography fontSize={below600 ? 14 : 32} fontWeight={500} margin="16px 0 8px">
        Social Links
      </Typography>
      <GeneralFlexBox direction="column" align="flex-start" gap="16px">
        {SOCIAL_LINKS.map((link, index) => (
          <CustomTextField
            key={index}
            placeholder={link.placeholder}
            value={socialLinks[index]}
            onChange={(e) => {
              const newLinks = [...socialLinks];
              newLinks[index] = e.target.value;
              setSocialLinks(newLinks);
            }}
            sx={{
              marginTop: 0,
              '& .MuiInputBase-input': { paddingLeft: link.adornment && '1px' },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ color: '#929292', marginRight: link.adornment && 0 }}
                >
                  {link.icon}
                  <Typography
                    paddingLeft={1}
                    color={(theme) => theme.palette.text.primary}
                    fontSize={14}
                  >
                    {link.adornment}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        ))}
      </GeneralFlexBox>
      <Box maxWidth={650} width="100%" padding="16px 0" display="flex" justifyContent="flex-end">
        <StandardButton onClick={handleSave} disabled={disabled || loading}>
          {!loading ? 'Save Social Links' : <span className="loading">Saving Social Links...</span>}
        </StandardButton>
      </Box>
    </>
  );
};

export default SocialMedia;
